import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js';

const apiService = Apiservice;

export default class UpdateRequestService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/update-request';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    create(data) {

        let url = `${this.#api}`;
        return apiService.post(url, data);

    }

    update(id, data) {
        let url = `${this.#api}/${id}`;
        return apiService.put(url, data)
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    review(id)
    {
        let url = `${this.#api}/${id}/review`
        return apiService.get(url)
    }
    archived(data={},index = null) {
        let url = `${this.#api}/archived`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
}